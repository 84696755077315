import React, { Component } from 'react';
import './App.scss';
import Wrapper from './components/Wrapper'

class App extends Component {
  render() {
    return (
    <Wrapper />
    );
  }
}

export default App;
